<template>
    <div class="modal" :class="{'is-active': $parent.show_notes_modal}">
        <div class="modal-background" @click.self="$parent.show_notes_modal = false"></div>
        <div class="modal-card">
            <section class="modal-card-body custom_scrollbar-1 p-0">
                <div class="content is-flex-direction-column mb-0 p-2 has-background-white-ter">
                    <div class="is-flex is-flex-direction-column">
                        <div class="is-flex is-justify-space-between">
							<div>
								<i class="is-block has-text-grey-dark">
									{{$t("notes_title")}} 
								</i>
								<!-- {{$parent.current_projects_jobs_material.Caption}} -->
                               <i class="has-text-grey"> {{$parent.current_projects_jobs_material.material_name}}</i>
							</div>
                            <a @click.prevent="show_create_new = !show_create_new" class="button is-small ml-auto mb-2">
                              {{$t("add_note")}} 
                                <i v-if="!show_create_new" class="fas fa-plus fa-sm ml-2"></i>
                                <i v-else class="fas fa-minus fa-sm ml-2"></i>
                            </a>
                        </div>
                        <div v-if="show_create_new" class="is-flex is-justify-content-space-between">
                            <div class="" style="width:100%" >
                                <div style="position:relative;">
                                    <textarea v-model="new_note.content" rows="2" class="textarea is-small" :placeholder="$t('note_placeholder')"></textarea>
                                    <button @click.prevent="show_create_new = !show_create_new" style="position:absolute; top:5px; right:5px;" class="delete is-small"></button>
                                </div>
                            </div>
                            <div class="is-align-self-flex-end is-flex is-justify-content-center" style="flex-shrink:8; width:100%;">
                                <a @click.prevent="save_note(new_note)" href="" class="button is-small">{{$t("create_note")}}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content is-flex-direction-column p-2">
                    <div v-for="(note, index) in selected_material_note_list" :key="index"
                         class="notification has-background-white is-light px-0 p-2 mb-0 has-border">
                         <p class="is-size-7 mb-0" >
                             <span class="has-text-grey">
                                <i class="fa fa-user fa-sm has-text-grey-lighter"></i> {{note.created_by_name}} 
                                <small class="has-text-grey">
                                    <i class="fa fa-calendar fa-sm has-text-grey-lighter"></i> {{note.updated_at != null ? note.updated_at : note.created_at | datetime }}
                                </small>
								<span style="text-transform:lowercase; color: #2fa1ba;">
									@{{note.step_name}}
								</span>
                             </span>
                            <span class="is-pulled-right">
                                <!-- <small v-if="note.updated_at != null" class="has-text-grey-light">
                                    Updated at: {{note.updated_at | datetime}}
                                </small>
                                <small v-else class="has-text-grey-light">
                                    Created at: {{ note.created_at | datetime }}
                                </small> -->
                                <a v-if="note.created_by == $parent.user.IdUser" @click.prevent="delete_note(note)" href="" class="ml-2">
                                    <i class="fas fa-trash-alt has-text-danger" style="opacity: 50%;" ></i>
                                </a>
                                <a v-if="note.created_by == $parent.user.IdUser" @click.prevent="switch_edit_note(note)" href="" class="ml-2" >
                                    <i class="fas fa-edit has-text-info" style="opacity: 50%;"></i>
                                </a>
                            </span>
                        </p>
                        <div>
                            <div class="is-flex is-flex-direction-column" v-if="note.edit_mode" >
                                <div style="position:relative;">
                                    <textarea v-model="note.content" rows="2" class="textarea is-small" placeholder="Create note"></textarea>
                                    <button @click.prevent="switch_edit_note(note)" style="position:absolute; top:5px; right:5px;" class="delete is-small"></button>
                                </div>
                                <a @click.prevent="save_note(note)" href="" class="button mt-2 is-small ml-auto">{{$t("save")}}</a>
                            </div>
                            <span v-else class="has-text-black">
                                {{note.content}}
                            </span>

                        </div>
                    </div>
                </div>
                <!-- Content ... -->
            </section>
        </div>
    </div>
</template>

<script>import axios from 'axios'
import socketMixin from '@/socket-mixin.js'
import socket from '@/socket.js'
export default {
    mixins: [ socketMixin ],
    data() {
        return {
            new_note: {},
            show_create_new: false,
            selected_material_note_list: []
        }
    },
    created(){
        this.get_material_notes()
    },
    mounted(){
        socket.on('someone said', this.socket_listener)
    },
    beforeDestroy() {
		socket.off("someone said", this.socket_listener);
	},
    methods: {
		socket_listener(data){
			console.log('socket at modal')
            if(data.type == 'add note'){
                if(data.data[0].material_id == this.$parent.current_projects_jobs_material.m_guid){
                    this.selected_material_note_list.unshift(data.data[0])
                    this.selected_material_note_list = [...this.selected_material_note_list]
                }
            }
            else if(data.type == 'delete note'){
                console.log('socket',data)
                this.selected_material_note_list = this.selected_material_note_list.filter(n => n.guid != data.data)
            }
            else if(data.type == 'edit note'){
                this.selected_material_note_list = [...this.selected_material_note_list.map(n => {
                    if(n.guid == data.data.guid){
                        n = data.data
                        n.edit_mode = false
                    }
                    return n
                })]
            }
		},
        get_material_notes(){
            axios.post(this.$tetris_server + '/get/material/notes', {
                m_id: this.$parent.current_projects_jobs_material.m_guid, 
				material: this.$parent.current_projects_jobs_material
            })
            .then(res => {
                this.selected_material_note_list = res.data
                this.selected_material_note_list = [...this.selected_material_note_list.map(n => {
                    n.edit_mode = false
                    return n
                })]
            })
        },
        save_note(note){
            if(note.content){
                // Create or edit
                let operation = 0
                note.guid ? operation = 2 : operation = 1, // 1-create, 2-edit
                axios.post(this.$tetris_server +'/create/material/note', {
                    note: note, 
                    operation: operation, 
                    material: this.$parent.current_projects_jobs_material, // material_id
                    created_by: this.$parent.user,
                    project_id: this.$parent.currentEvent, 
                    step_name: this.$parent.current_projects_jobs_material.step_name,
                    ej_material_id: this.$parent.current_projects_jobs_material.ej_material_id,
                    job_id: this.$parent.current_projects_jobs_material.job_id
                })
                .then(r => {
                    if(operation == 1){
                        this.new_note = {}
                        this.show_create_new = false
                        this.selected_material_note_list.unshift(r.data[0])
                        // Add new note to ...

                        this.send_new_message(this.selected_material_note_list)
                        socket.emit('tell others', { type: 'add note', data: r.data})
                        this.$toast(this.$t("toaster_note_created"), 'success')
                    }
                    else if(operation == 2){
                        // Return from edit
                        this.selected_material_note_list = this.selected_material_note_list.map(n => {
                            // console.log(n.guid, '-', r.data)
                            if(n.guid == r.data.guid){
                                n = r.data
                                this.$toast(this.$t("toaster_note_saved"), 'success')
                                n.edit_mode = false
                            }
                            return n
                        })
                        socket.emit('tell others', {type: 'edit note', data: r.data})
                    }

                    this.$parent.current_projects_jobs_material.notes_all += 1

                    // console.log(this.$parent.current_projects_jobs_material)
                    this.$parent.jobs = [...this.$parent.jobs.map(j => {
                        j.materials = [...j.materials.map(m => {
                            m.date_amount = [...m.date_amount.map(md => {
                                if(md && md.m_guid == this.$parent.current_projects_jobs_material.m_guid){

                                    md.notes_all += 1
                                    if(this.$parent.current_projects_jobs_material.step_name == "WAREHOUSE"){
                                        if(!md.warehouse_notes){
                                            md.warehouse_notes = 1
                                        } else {
                                            md.warehouse_notes = parseInt(md.warehouse_notes) + 1
                                        }
                                    }
                                    else if(this.$parent.current_projects_jobs_material.step_name == "PLANNING"){
                                        if(!md.planning_notes){
                                            md.planning_notes = 1
                                        } else {
                                            md.planning_notes = parseInt(md.planning_notes) + 1
                                        }
                                    }
                                    else if(this.$parent.current_projects_jobs_material.step_name == "REQUEST"){
                                        if(!md.request_notes){
                                            md.request_notes = 1
                                        } else {
                                            md.request_notes = parseInt(md.request_notes) + 1
                                        }
                                    }


                                }
                                return md
                            })]

                            return m
                        })]
                        return j
                    })]
                })
            }
            else{
                this.$toast('The note should not be empty', 'error')
            }
        },
        delete_note(n){
            this.$fire({
                // title: 'Are you sure?',
                text: this.$t("are_you_sure"),
                showCancelButton: true,
                confirmButtonText: this.$t("yes"),
                cancelButtonText: this.$t("no"),
                width: 300,
                customClass: {
                    confirmButton: 'button is-success is-small',
                    cancelButton: 'button is-small',
                },
            }).then(response => {
                if(response.value){
                    axios.post(this.$tetris_server +'/delete/material/note', {guid: n.guid})
                    .then(res => {
                        this.selected_material_note_list = this.selected_material_note_list.filter(n => n.guid != res.data)
                        socket.emit('tell others', {
                            type: 'delete note', 
                            data: res.data
                        })
                        this.$toast(this.$t('toaster_note_deleted'), 'error')
                    })
                } 
            });
        },
        switch_edit_note(n){
            // console.log('selmat', this.selected_material_note_list)
            // Return original value (note.content) after cancel
            this.selected_material_note_list = this.selected_material_note_list.map(note1 => {
                // console.log('found! ', note1)
                if(note1.guid == n.guid){
                    note1.edit_mode = !note1.edit_mode
                }
                return note1
            })
        },
        
    },

}
</script>

<style>

</style>
